import { Container, FlexGrid, Header, Image, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function Steps({ blocks, imageRight, subtitle, title, offsetLeft, offsetRight }) {
  const imageToSrc = useImageSrcBuilder();

  const leftOffset = parseInt(offsetLeft, 10) || 0;
  const rightOffset = parseInt(offsetRight, 10) || 0;

  // Calculate available columns after subtracting offsets
  const availableColumns = 12 - leftOffset - rightOffset;

  // Determine if we have an image to display
  const hasImage = blocks[0]?.image?.url;

  // Ensure image gets at least 3 columns if present
  const imageColumns = hasImage ? Math.max(3, Math.floor(availableColumns * 0.35)) : 0;

  // Calculate content columns based on available space and image presence
  const contentColumns = hasImage ? availableColumns - imageColumns : availableColumns;

  return (
    <section className={cx('section', 'steps')}>
      <Container>
        {title && (
          <Header as="h2" className={cx('header')}>
            <Markup source={title} />
          </Header>
        )}
        <FlexGrid container className={cx('stepsContent')}>
          {/* Left offset */}
          {leftOffset > 0 && (
            <FlexGrid.Column tablet={leftOffset} className={cx('offset')} />
          )}

          {/* Image on the left */}
          {!imageRight && hasImage && (
            <FlexGrid.Column
              className={cx('stepsImageColumn')}
              tablet={imageColumns}
            >
              <Image alt={blocks[0].alt} className={cx('stepsImage', 'left')} src={imageToSrc(blocks[0].image)} />
            </FlexGrid.Column>
          )}

          {/* Content column */}
          <FlexGrid.Column tablet={contentColumns}>
            {subtitle && (
              <Header as="h3" className={cx('subtitle')}>
                <Markup source={subtitle} />
              </Header>
            )}
            <div className={cx('stepsList')}>
              {blocks.map((block, idx) => (
                <div key={block.description} className={cx('stepRow')}>
                  <div className={cx('stepNumber')}>
                    <Text>{idx + 1}</Text>
                  </div>
                  <div className={cx('stepDescription')}>
                    <Markup source={block.description} />
                  </div>
                </div>
              ))}
            </div>
          </FlexGrid.Column>

          {/* Image on the right */}
          {imageRight && hasImage && (
            <FlexGrid.Column
              tablet={imageColumns}
              className={cx('stepsImageColumn')}
            >
              <Image alt={blocks[0].alt} className={cx('stepsImage', 'right')} src={imageToSrc(blocks[0].image)} />
            </FlexGrid.Column>
          )}

          {/* Right offset */}
          {rightOffset > 0 && (
            <FlexGrid.Column tablet={rightOffset} className={cx('offset')} />
          )}
        </FlexGrid>
      </Container>
    </section>
  );
}

Steps.propTypes = {
  blocks: PropTypes.arrayOf(Block),
  imageRight: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  offsetLeft: PropTypes.string,
  offsetRight: PropTypes.string,
};

Steps.defaultProps = {
  blocks: [],
  imageRight: false,
  subtitle: undefined,
  title: undefined,
  offsetLeft: '0',
  offsetRight: '0',
};
