import classNames from 'classnames/bind';
import React, { Fragment, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import useImageSrcBuilder from '../../../lib/hooks/useImageSrcBuilder';
import generateCMSMetaTags from '../../../lib/utils/generateCMSMetaTags';
import { LandingPagePropType } from '../../prop-types/cms-page';
import Page from '../Page';
import styles from './LandingPageTemplate2.module.css';
import { About } from './Sections/About';
import { Bios } from './Sections/Bios';
import { BrandShowcase } from './Sections/BrandShowcase';
import { Hero, HeroSideInfo } from './Sections/Heroes';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { MetricsRow } from './Sections/MetricsRow';
import { Steps } from './Sections/Steps';
import { Terms } from './Sections/Terms';
import { FAQs } from './Sections/FAQs';

const cx = classNames.bind(styles);

function renderSection(section, createCTAClickHandler, pageFaqs) {
  switch (section.name) {
    case 'about':
      return <About {...section} />;
    case 'aboutImageRight':
      return <About {...section} imageRight />;
    case 'bios':
      return <Bios {...section} />;
    case 'brands':
      return <BrandShowcase {...section} />;
    case 'faqs':
      return <FAQs faqs={pageFaqs} />; // no faq info is contained in `section`
    case 'hero':
      return <Hero {...section} />;
    case 'heroSideInfo':
      return (
        <HeroSideInfo
          {...section}
          onClick={createCTAClickHandler(section.displayName || section.name)}
        />
      );
    case 'heroSideInfoRight':
      return (
        <HeroSideInfo
          {...section}
          infoRight
          onClick={createCTAClickHandler(section.displayName || section.name)}
        />
      );
    case 'horizontalLine':
      return <hr className={cx('horizontalLine')} />;
    case 'metricsRow':
      return (
        <MetricsRow
          {...section}
          onClick={createCTAClickHandler(section.displayName || section.name)}
        />
      );
    case 'steps':
      return <Steps {...section} />;
    case 'stepsImageRight':
      return <Steps {...section} imageRight />;
    case 'termsAndConditions':
      return <Terms {...section} />;
    default:
      return null;
  }
}

function LandingPageTemplate2({ page }) {
  const location = useLocation();
  const imageToSrc = useImageSrcBuilder();
  const attachQueryString = baseUrl => `${baseUrl}${location.search}`;
  const meta = page?.meta?.find(el => el.type === 'meta');

  const analytics = useAnalytics();

  const createCTAClickHandler = useCallback(sectionName => () => {
    if (page?.ctaEventName) {
      analytics.tagEvent({
        name: page.ctaEventName,
        attributes: {
          'Section Name': sectionName || 'Unknown',
          'Screen Name': page?.name || 'Unknown',
        },
      });
    }
  }, [analytics, page]);

  return (
    <Page
      className={cx('page')}
      compact
      name={page?.name}
      title={page?.title}
      description={meta?.content}
    >
      <Helmet>
        {page?.title && (
          <title>{page.title}</title>
        )}
        {page?.canonicalUrl && (
          <link rel="canonical" href={attachQueryString(page.canonicalUrl)} />
        )}
        {!!page?.meta?.length && generateCMSMetaTags(page.meta, imageToSrc)}
      </Helmet>
      {page?.sectionList?.map(section => (
        <Fragment key={`${section.name}_${section.displayName || section.title}`}>
          {renderSection(section, createCTAClickHandler, page?.faqs)}
        </Fragment>
      ))}
    </Page>
  );
}

LandingPageTemplate2.propTypes = {
  page: LandingPagePropType,
};

LandingPageTemplate2.defaultProps = {
  page: undefined,
};

const ForwardedLandingPageTemplate2 = React.forwardRef((props, ref) => <LandingPageTemplate2 {...props} ref={ref} />);

export default ForwardedLandingPageTemplate2;
