import { Button, Container, FlexGrid, Header, Image, Text } from '@gasbuddy/react-components';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import useImageSrcBuilder from '../../../../lib/hooks/useImageSrcBuilder';
import { Block } from '../../../prop-types/cms-page';
import Markup from '../../Markup';
import styles from '../LandingPageTemplate2.module.css';

const cx = classNames.bind(styles);

export function About({ blocks, imageRight }) {
  const imageToSrc = useImageSrcBuilder();

  return blocks.map((block) => {
    const offsetLeft = block.offsetLeft || 0;
    const offsetRight = block.offsetRight || 0;

    // Calculate the available width after accounting for offsets
    const availableColumns = 12 - offsetLeft - offsetRight;

    // Determine the column widths based on image presence and available space
    const imageColumns = block.image?.url ? Math.min(3, Math.floor(availableColumns / 2)) : 0;
    const contentColumns = block.image?.url ? availableColumns - imageColumns : availableColumns;

    return (
      <div className={cx('section')} key={block.title || block.description}>
        <Container>
          <FlexGrid container className={cx('centerItems')}>
            {/* Apply offset for left margin if specified */}
            {offsetLeft > 0 && (
              <FlexGrid.Column tablet={offsetLeft} className={cx('offset')} />
            )}

            {!imageRight && block.image?.url && (
              <FlexGrid.Column tablet={imageColumns}>
                <Image alt={block.alt} className={cx('aboutImage', 'left')} fluid src={imageToSrc(block.image)} />
              </FlexGrid.Column>
            )}

            <FlexGrid.Column tablet={contentColumns}>
              <Header
                as="h2"
                className={cx('title', !block.titleImage ? (block.titleSize || 'medium') : '')}
                style={{ textAlign: block.titleAlignment || 'left' }}
              >
                {block.titleImage ? (
                  <Image alt={block.title} className={cx('titleImage')} src={imageToSrc(block.titleImage)} />
                ) : (
                  <Markup source={block.title} />
                )}
              </Header>
              <Text
                as="p"
                className={cx('description', block.descriptionSize || '')}
                style={{ textAlign: block.descriptionAlignment || 'left' }}
              >
                <Markup source={block.description} />
              </Text>
              {block.ctaLabel && block.ctaUrl && (
                <Button as="a" className={cx('cta')} href={block.ctaUrl} primary wide>{block.ctaLabel}</Button>
              )}
              {block.ctaDisclaimer && (
                <Text className={cx('ctaDisclaimer')} size="sm">{block.ctaDisclaimer}</Text>
              )}
            </FlexGrid.Column>

            {imageRight && block.image?.url && (
              <FlexGrid.Column tablet={imageColumns}>
                <Image alt={block.alt} className={cx('aboutImage', 'right')} fluid src={imageToSrc(block.image)} />
              </FlexGrid.Column>
            )}

            {/* Apply offset for right margin if specified */}
            {offsetRight > 0 && (
              <FlexGrid.Column tablet={offsetRight} className={cx('offset')} />
            )}
          </FlexGrid>
        </Container>
      </div>
    );
  });
}

About.propTypes = {
  blocks: PropTypes.arrayOf(Block),
};

About.defaultProps = {
  blocks: [],
};
